/**
 * @file 用户store
 * @author jianghaoran01
 */
import {createSlice} from '@reduxjs/toolkit';
import {author} from '@request/author';
import request, {post} from '@request/service';

const initialState = {
    detail: {
        name: '用户',
        id: '1111'
    },
    logined: false
};

export const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        updateUserDetail: (state, action) => (state.detail = action.payload),
        updateLogined: (state, action) => (state.logined = action.payload)
    }
});

export const user = state => state.userSlice;

export const {updateUserDetail, updateLogined} = userSlice.actions;

