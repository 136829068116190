const localeValues = {
    pageHeader: {
        title: 'PIKA'
    },
    login: {
        title: '在线报名系统',
        username: '账号',
        password: '密码',
        login: '登录'
    },
    menu:{
        home:'报名管理',
        exams:'报名管理',
        examPlaces:'考点管理',
    },
    footer:{
        privacy:'京ICP备2022015257号-1'
    },
    home:{
        title:'公告列表',
        add:'新增公告'
    },
    noticeMain:{
        title:'新增公告',
        editTitle:'编辑公告',
        head:'文章名称',
        type:'文章类型',
        content:'文章内容',
        time:'发布时间',
        submit:'发布',
        cancel:'取消'
    },
    registerMain:{
        title:'新增报名',
        editTitle:'编辑报名',
        head:'考试名称',
        type:'考点类型',
        signTime:'报名时间',
        time:'考试时间',
        area:'报考区域',
        submit:'创建',
        editSubmit:'确认',
        cancel:'取消'
    },
    examsList:{
        title:'考试列表',
        add:'新增报名',
    },
    exams:{
        title:'考试列表',
        add:'新增报名',
        modalTitle:'新增报名',
        modalEditTitle:'编辑报名',
        modalName:'考试名称',
        modalType:'考点类型',
        modalConfirm:'确认',
        modalCancel:'取消'
    },
   areas:{
        title:'考场列表',
        add:'新增考场',
        modalTitle:'新增考场',
        modalEditTitle:'编辑考场',
        modalName:'考场名称',
        modalSecondName:'子考场',
        modalAreaName:'名称',
        modalAreaCap:'容量',
        modalConfirm:'确认',
        modalCancel:'取消'
    },
    students:{
        buttonConfig:'准考证配置',
        buttonDownload:'报名考生下载',
        modalTitle:'准考证配置',
        modalEditTitle:'准考证配置',
        modalName:'发布时间',
        modalType:'准考证附件',
        modalConfirm:'确定',
        modalCancel:'取消',
        modalClose:'关闭'
    }
}

export default localeValues;